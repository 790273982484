import React from 'react';
import Layout from '../components/layout';
import LandingPageImage from '../components/image/LandingPageImage';
import SEO from '../components/seo';
import Container from '../components/container';
import LinkButton from '../components/button-link';
import styles from './index.module.scss';
import SoccermatchPreviewImage from '../components/image/SoccermatchPreviewImage';
import Footer from '../components/footer';
import MakeAChoiceImage from '../components/image/MakeAChoiceImage';
import { classNames } from 'react-extras';

const IndexPage = () => (
  <Layout>
    <SEO />

    <Container className={styles.LogoSection}>
      <h1 className="visually-hidden">SoccerMatch</h1>
      <LandingPageImage className={styles.LogoSection__LandingPageImage} />
    </Container>

    <Container className={classNames('flex')}>
      <div className={classNames('f-1')}>
        <SoccermatchPreviewImage className={classNames(styles.Image)} />
      </div>
      <div className={classNames('f-2')}>
        <h2>Zoek een match</h2>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Rationis enim
          perfectio est virtus; Cave putes quicquam esse verius. Certe, nisi
          voluptatem tanti aestimaretis. Duo Reges: constructio interrete. Sed
          mehercule pergrata mihi oratio tua. Audeo dicere, inquit. Nihil enim
          hoc differt. Eam tum adesse, cum dolor omnis absit;
        </p>
      </div>
    </Container>
    <Container className={classNames('flex')}>
      <div className={classNames('f-1', 'o-2')}>
        <MakeAChoiceImage className={classNames(styles.Image)} />
      </div>

      <div className={classNames('f-2', 'o-1')}>
        <h2>Voor iedereen</h2>

        <p>
          Quid adiuvas? Et non ex maxima parte de tota iudicabis? Audeo dicere,
          inquit. At iam decimum annum in spelunca iacet. Sed hoc sane
          concedamus.
        </p>
      </div>
    </Container>

    <Container>
      {/* <Divider /> */}
      <h2>Wees er als eerste bij!</h2>
      <p>
        We gaan binnenkort starten met de eerste beta-versie van SoccerMatch,
        wees er als eerste bij door je in te schrijven voor de wachtlijst!
      </p>
      <p>
        <LinkButton to="/waiting-list">Schrijf je in</LinkButton>
      </p>
    </Container>

    <Container>
      <Footer />
    </Container>
  </Layout>
);

export default IndexPage;
