import React from 'react';
import { Link } from 'gatsby';
import mergeProps from '../util/merge-props';
import styles from './button.module.scss';

/**
 * @param {import("gatsby").GatsbyLinkProps} props
 */
const LinkButton = props => (
  <Link {...mergeProps({ className: styles.Button }, props)}></Link>
);

export default LinkButton;
